import i1 from "../../images/shop/26thprintTokyo2174/1_tokyo.jpg";
import i2 from "../../images/shop/26thprintTokyo2174/2_tokyo.jpg";
import i3 from "../../images/shop/26thprintTokyo2174/3_tokyo.jpg";
import i4 from "../../images/shop/26thprintTokyo2174/4_tokyo.jpg";
import i5 from "../../images/shop/26thprintTokyo2174/5_tokyo.jpg";
import i6 from "../../images/shop/26thprintTokyo2174/6_tokyo.jpg";


const tokyoCollection = [
  { image: i1, alt: "Tokyo 2174" },
  { image: i2, alt: "Tokyo 2174" },
  { image: i3, alt: "Tokyo 2174" },
  { image: i4, alt: "Tokyo 2174" },
  { image: i5, alt: "Tokyo 2174" },
  { image: i6, alt: "Tokyo 2174" },
  
];

export default tokyoCollection;
