import i1 from "../../images/shop/SoldOutPrints/eastRedfern2021.jpg";
import i2 from "../../images/shop/SoldOutPrints/manly2021.jpg";
import i3 from "../../images/shop/SoldOutPrints/northBondi2016.jpeg";
import i4 from "../../images/shop/SoldOutPrints/ottawa2021.jpg";
import i5 from "../../images/shop/SoldOutPrints/paddington2021.jpg";
import i6 from "../../images/shop/SoldOutPrints/strangeParadise2019.jpg";
import i7 from "../../images/shop/SoldOutPrints/surryHills2021.jpg";
import i8 from "../../images/shop/SoldOutPrints/thredbo2021.jpg";
import i9 from "../../images/shop/SoldOutPrints/thredboIII2022.jpg";
import i10 from "../../images/shop/SoldOutPrints/unicornsAtBondi2016.jpg";
import i11 from "../../images/shop/SoldOutPrints/whatHappensOnEarth2017.jpg";
import nycPic from "../../images/shop/SoldOutPrints/1_snowyDayNYC.jpg";
import i12 from "../../images/shop/SoldOutPrints/1_paddoThree.jpg";
import i13 from "../../images/shop/SoldOutPrints/1_theOptimist.jpg";
import i14 from "../../images/shop/SoldOutPrints/1_strangeParadise4.jpg";
import i15 from "../../images/shop/SoldOutPrints/1_chelseaNYC.jpg";
import i16 from "../../images/shop/SoldOutPrints/1_dinnerParty.jpg";
import i17 from "../../images/shop/SoldOutPrints/1_bondiIcebergs.jpg";
import i18 from "../../images/shop/SoldOutPrints/1_sohoMickey.jpg";
import i19 from "../../images/shop/SoldOutPrints/1_nycSuperheroes.jpg";

/*
imageCollection is an [array,array,array] of {objects:objects,objects:objects}, structured as follows:
{
  image: importedImage
  title:"Mona Lisa"
  description: "Oil on Canvas, 2017 etc",
  alt:"Painting of a woman with an ambiguous smile, with a bleak landscape behind her",
  sold: true,
  price: "6300"

}
image must be imported from it's relative location at the top of this script.
title and description will display beneath each image,
alt is for screen readers for visually impaired people, put a more detailed description here.
sold is optional, true will add a little red "-SOLD" next to the title
price is optional, will add a price to the label
*/

const soldOutCollection = [
  {
    image: i19,
    title: "The Superheroes and a photoshoot (New York) - 2024",
    alt: "The Superheroes and a photoshoot (New York) - 2024, Print by Tyrone Layne",
    labelLine2: "Limited Edition of 12 Prints",
  },
  {
    image: i18,
    title: "Steamboat Mickey in SoHo - 2024",
    alt: "Steamboat Mickey in SoHo - 2024, Print by Tyrone Layne",
    labelLine2: "Limited Edition of 12 Prints",
  },
  {
    image: i15,
    title: "Chelsea NYC - 2023",
    alt: "Chelsea NYC - 2023, Print by Tyrone Layne",
    labelLine2: "Limited Edition of 9 Prints",
  },
  {
    image: i17,
    title: "Bondi Icebergs - 2021",
    alt: "Bondi Icebergs - 2021, Print by Tyrone Layne",
    labelLine2: "Limited Edition of 50 Prints",
  },
  {
    image: i16,
    title: "The Dinner Party - 2023",
    alt: "The Dinner Party - 2023, Print by Tyrone Layne",
    labelLine2: "Limited Edition of 9 Prints",
  },
  {
    image: i14,
    title: "Strange Paradise IV - 2023",
    alt: "Strange Paradise IV - 2023, Print by Tyrone Layne",
    labelLine2: "Limited Edition of 9 Prints",
  },
  {
    image: i13,
    title: "The Optimist - December 2020",
    alt: "The Optimist - December 2020, Print by Tyrone Layne",
    labelLine2: "Limited Edition of 100 Prints",
  },
  {
    image: i12,
    title: "A Day in Paddo III - February 2022",
    alt: "A Day in Paddo III - February 2022, Print by Tyrone Layne",
    labelLine2: "Limited Edition of 25 Prints",
  },
  {
    image: nycPic,
    title: "Snowy Day, Downtown, NYC - January 2021",
    alt: "Snowy Day, Downtown, NYC - January 2021, Print by Tyrone Layne",
    labelLine2: "Limited Edition of 35 Prints",
  },
  {
    image: i9,
    title: "Thredbo III - February 2022",
    alt: "Thredbo III - February 2022, Print by Tyrone Layne",
    labelLine2: "Limited Edition of 25 Prints",
  },
  {
    image: i4,
    title: "Rideau Canal, Ottawa- December 2021.",
    alt: "Rideau Canal, Ottawa- December 2021, Print by Tyrone Layne",
    labelLine2: "Limited Edition of 7 Prints",
  },
  {
    image: i2,
    title: "Manly III- June 2021.",
    alt: "Manly III- June 2021, Print by Tyrone Layne",
    labelLine2: "Limited Edition of 9 Prints",
  },
  {
    image: i7,
    title: "Surry Hills - August 2021",
    alt: "Surry Hills - August 2021, print by Tyrone Layne",
    labelLine2: "Limited Edition of 9 Prints",
  },
  {
    image: i8,
    title: "Thredbo - June 2021",
    alt: "Thredbo - June 2021, Print by Tyrone Layne",
    labelLine2: "Limited Edition of 9 Prints",
  },
  {
    image: i1,
    title: "East Redfern - June 2021",
    alt: "East Redfern - June 2021, Print by Tyrone Layne",
    labelLine2: "Limited Edition of 7 Prints.",
  },
  {
    image: i5,
    title: "Paddington VII- January 2021",
    alt: "Paddington VII- January 2021, Print by Tyrone Layne",
    labelLine2: "Limited Edition of 7 Unique Canvas Prints",
  },
  {
    image: i6,
    title: "Strange Paradise - May 2019",
    alt: "Strange Paradise - May 2019, Print by Tyrone Layne",
    labelLine2: "Limited Edition of 50 Prints",
  },
  {
    image: i10,
    title: "Unicorns at Bondi - November 2016",
    alt: "Unicorns at Bondi - November 2016, Print by Tyrone Layne",
    labelLine2: "Limited Edition of 50 Prints",
  },
  {
    image: i11,
    title: "What Happens on Earth, Stays on Earth - 2017",
    alt: "What Happens on Earth, Stays on Earth - May 2017, Print by Tyrone Layne",
    labelLine2: "Limited Edition of 50 Prints",
  },
  {
    image: i3,
    title: "North Bondi- Feburary 2016",
    alt: "North Bondi- Feburary 2016, Print by Tyrone Layne",
    labelLine2: "Limited Edition of 50 Unique Prints",
  },
];

export default soldOutCollection;
