const bioInfo = {
  Name: "Tyrone Layne",
  "Date of Birth": "1985",
  "Birth Place": "Auckland, New Zealand",
  "Current Studio": "Sydney, Australia",
  Education:
    "Bachelor of Design degree at UNITEC Auckland, majoring in Painting. 2004-2007.",
  Email: "tyrone@tyronelayne.com",
 };

export default bioInfo;
