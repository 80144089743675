import React from "react";
import ImageLink from "../components/ImageLink";
import HeaderSpacer from "../components/HeaderSpacer";
import { Helmet } from "react-helmet-async";
import Image from "../components/Image";
import { nanoid } from "nanoid";
import UpButton from "../components/UpButton";

import hollywoodPic from "../images/shop/5thprintHollywood/1_hollywoodHills.jpg";
import tokyoPic from "../images/shop/26thprintTokyo2174/1_tokyo.jpg";
import thredbo4Pic from "../images/shop/20thprintThredbo4/1_thredbo4.jpg";
import theOptimist2Pic from "../images/shop/22ndprintTheOptimist2/1_theOptimist2.jpg";
import parisPic from "../images/shop/24thprintParis/1_paris.jpg";
import soldOutCollection from "../data/shop/soldOutPrints_Data";
// import AwayAlert from "../components/AwayMessage";

function PrintShop() {
  return (
    <div>
      <Helmet>
        <title>Tyrone Layne | Shop | Prints</title>
        <link rel='canonical' href='http://tyronelayne.com/prints' />
      </Helmet>
      <HeaderSpacer />
      {/* <AwayAlert
        message="Attention- I'll be away from the studio May 13th-27th 2024. Any prints
        ordered between these dates will be shipped once I
        return to Sydney."
  />*/}
      <ImageLink
        source={theOptimist2Pic}
        description="Click to view 'The Optimist II"
        cssClass='theOptimist2Pic'
        linkTo='/prints/theoptimist2'
        label='The Optimist II - 2023'
        labelLine2='Limited Edition of 25 Prints'
      />
      <ImageLink
        source={tokyoPic}
        description="Click to view 'Tokyo 2174"
        cssClass='tokyoPic'
        linkTo='/prints/tokyo'
        label='Tokyo 2174 - 2024'
        labelLine2='Limited Edition of 12 Prints'
      />
       <ImageLink
        source={parisPic}
        description="Click to view 'Paris: A Moveable Feast"
        cssClass='parisPic'
        linkTo='/prints/paris'
        label='Paris: A Moveable Feast - 2024'
        labelLine2='Limited Edition of 12 Prints'
      />
      <ImageLink
        source={thredbo4Pic}
        description="Click to view 'Thredbo IV"
        cssClass='Thredbo4Pic'
        linkTo='/prints/thredbo'
        label='Thredbo IV - 2023'
        labelLine2='Limited Edition of 35 Prints'
      />
      <ImageLink
        source={hollywoodPic}
        description="Click to view 'Parenting in the Hollywood Hills'"
        cssClass='hollywoodPic'
        linkTo='/prints/hollywood'
        label='Parenting in the Hollywood Hills - 2020'
        labelLine2='Limited Edition of 50 Prints'
      />

      {soldOutCollection.map(thePic => (
        <Image
          source={thePic.image}
          description={thePic.alt}
          label={thePic.title}
          sold={true}
          cssClass={thePic.title}
          labelLine2={thePic.labelLine2}
          key={nanoid()}
        />
      ))}
      <UpButton linkTo='/shop' />
    </div>
  );
}

export default PrintShop;
